import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    deletePopup:false,
    selectedAdmin: {},
}


export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setDeletePopup: (state, action) => {
            state.deletePopup = action.payload
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload
        },

	},
})

export const { setCurrentRouteKey,setDeletePopup,setSelectedAdmin } = commonSlice.actions

export default commonSlice.reducer
import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
		url: '/auth/google',
		method: 'get',
		data,
	});
}

export async function apiGetMe() {
	    return ApiService.fetchData({
			url: '/auth/me',
			method: 'get'
		});
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/auth/sign-out',
        method: 'delete'
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}

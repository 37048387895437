import { THEME_ENUM } from 'constants/theme.constant';

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
	themeColor: 'p_primary',
	direction: 'ltr',
	mode: 'light',
	locale: 'en',
	primaryColorLevel: 600,
	cardBordered: false,
	panelExpand: false,
	controlSize: 'md',
	navMode: 'light',
	layout: {
		type: THEME_ENUM.LAYOUT_TYPE_SIMPLE,
		sideNavCollapse: true,
	},
};
